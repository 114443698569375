import { createContext } from "react"

const defaultValue = {
    location: {},
    setLocation: () => {},
    name: "",
    setName: () => {},
    price: "",
    setPrice: () => {},
    link: "",
    setLink: () => {}
}

const Context = createContext(defaultValue)

export default Context