// extracted by mini-css-extract-plugin
export var bgdark = "shared-module--bgdark--87593";
export var bglight = "shared-module--bglight--d92a3";
export var blue = "shared-module--blue--d600d";
export var blueButton = "shared-module--blueButton--24a39";
export var bold = "shared-module--bold--e8ba1";
export var button = "shared-module--button--df8dd";
export var dots = "shared-module--dots--fe6cd";
export var download = "shared-module--download--067c3";
export var gradientToBottom = "shared-module--gradientToBottom--bc25b";
export var gradientToTop = "shared-module--gradientToTop--ac4e3";
export var h100vh = "shared-module--h100vh--cfe52";
export var h100vhm60 = "shared-module--h100vhm60--6475b";
export var h100vhm60m = "shared-module--h100vhm60m--fa619";
export var jura = "shared-module--jura--8df21";
export var large = "shared-module--large--c2fa8";
export var rubik = "shared-module--rubik--f75b5";
export var white = "shared-module--white--684af";
export var xlarge = "shared-module--xlarge--a903b";