import * as React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { rubik, bgdark, white } from '../shared.module.css'
import { Link } from "gatsby"
import { border, link } from './footer.module.css'
import Year from '../../../common/src/components/year'

export default function Footer() {
    return (
        <>
            <Container fluid className={`${bgdark} ${white} ${border}`}>
                <Row className="text-center">
                    <Col className="p-5">
                        <img src="/brand.svg" alt="stanton" />
                        <p className={`mt-3 ${rubik}`}>
                            Part of the&nbsp;
                            <a href="https://www.inmusicbrands.com/" target="_blank" rel="noreferrer" className={link}>
                                inMusic family of brands
                            </a>
                            &nbsp;© <Year />. All Rights Reserved.
                            <br />
                            <Link to='/privacy' className={link}>
                                Privacy Policy
                            </Link>
                            &nbsp;/&nbsp;
                            <Link to='/terms-of-use' className={link}>
                                Terms of Use
                            </Link>
                            &nbsp;/&nbsp;
                            <a href="https://support.stantondj.com/en/support/home" target="_blank" rel="noreferrer" className={link}>
                                Contact Us
                            </a>
                            <div id="teconsent"></div>
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
