import 'bootstrap/dist/css/bootstrap.min.css'
import * as React from "react"
import Footer from "./components/footer"
import Nav from './components/nav'
import Context from './context'
import { useState } from "react"
import { bgdark, white } from './shared.module.css'
import './global.css'

export default function Layout({ children }){
    const [location, setLocation] = useState(null)
    const [name, setName] = useState("")
    const [price, setPrice] = useState("")
    const [link, setLink] = useState("")

    const values = {
        location,
        setLocation,
        name, 
        setName, 
        price, 
        setPrice, 
        link, 
        setLink
    }

    return(
        <Context.Provider value={values}>
            <Nav />
            <main className={`${bgdark} ${white}`}>
                {children}
            </main>
            <footer>
                <Footer />
            </footer>
        </Context.Provider>
    )
}
