import * as React from "react"
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { navbar, navlink, toggle, selected, notselected } from './nav.module.css'
import { rubik, button, white, blue, bgdark } from '../shared.module.css'
import { BsFacebook, BsTwitter, BsYoutube } from 'react-icons/bs'
import Context from "../context"
import { useContext, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { BsArrowRightShort } from 'react-icons/bs'
import { Link } from 'gatsby'

export default function StantonNav() {
	const { location, name, price, link } = useContext(Context)
	const [show, setShow] = useState(false)

	return (
		<Navbar sticky='top' bg="" expand="xl" className={navbar}>
			<Container className="">
				<Navbar.Brand as={Link} to="/" onClick={() => setShow(false)} style={{ paddingRight: '1rem' }}>
					<img src="/brand.svg" alt="stanton" />
				</Navbar.Brand>

				<Navbar.Toggle onClick={() => setShow(true)} className={toggle}>
					<img src="/toggle.svg" alt="toggle" />
				</Navbar.Toggle>

				<Navbar.Offcanvas show={show} onHide={() => setShow(false)} className={bgdark} id={`offcanvasNavbar-expand-xl`} aria-labelledby={`offcanvasNavbarLabel-expand-xl`} placement="end">
					
					<Offcanvas.Header closeButton closeVariant="white" className={white}>
						<Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
							<Link to='/' onClick={() => setShow(false)}>
								<img src="/brand.svg" alt="stanton" />
							</Link>
						</Offcanvas.Title>
					</Offcanvas.Header>

					<Offcanvas.Body className="d-flex flex-column justify-content-between">
						<div className="d-flex flex-column flex-xl-row justify-content-between w-100">
							<Nav>
								<Nav.Link as={Link} to="/stx" onClick={() => setShow(false)} className={`${navlink} ${rubik} ${location?.pathname === '/stx/' ? selected : notselected} d-flex justify-content-between`}>
									STANTON STX
									<BsArrowRightShort size='2rem' className="d-xl-none" />
								</Nav.Link>
								<Nav.Link as={Link} to="/ds4" onClick={() => setShow(false)} className={`${navlink} ${rubik} ${location?.pathname === '/ds4/' ? selected : notselected} d-flex justify-content-between`}>
									STANTON DS4
									<BsArrowRightShort size='2rem' className="d-xl-none" />
								</Nav.Link>
								<Nav.Link href="https://profile.inmusicbrands.com/" className={`${navlink} ${rubik} ${notselected} d-flex justify-content-between`} rel="noreferrer" target="_blank">
									REGISTER YOUR PRODUCT
									<BsArrowRightShort size='2rem' className="d-xl-none" />
								</Nav.Link>
								<Nav.Link href="https://support.stantondj.com/en/support/home" className={`${navlink} ${rubik} ${notselected} d-flex justify-content-between`} rel="noreferrer" target="_blank">
									SUPPORT
									<BsArrowRightShort size='2rem' className="d-xl-none" />
								</Nav.Link>
							</Nav>

							{
								location &&
								<div className="d-flex align-items-center mx-3">
									<a href={link} className={`${button}`} style={{ marginRight: '1rem' }}>BUY {name}</a>
									<div className="d-flex flex-column text-start">
										<span className={blue}>{price}</span>
										<img src="/at-inmusic-store.svg" alt="at inmusic" />
									</div>
								</div>
							}
						</div>

						<div>
							<div className="d-flex d-xl-none align-items-center justify-content-end mb-3 mb-lg-0 p-3 p-xl-0">
								<Link to='/' onClick={() => setShow(false)}>
									<img src="/brand.svg" alt="stanton" />
								</Link>
							</div>
							<div className="d-flex d-xl-none align-items-center justify-content-end mb-3 mb-lg-0 p-3 p-xl-0">
								<a href="https://www.facebook.com/StantonDJ" aria-label="Facebook"><BsFacebook color="white" size='1.75rem' /></a>
								<a href="https://twitter.com/StantonDJ4LF" aria-label="Twitter" className="mx-4"><BsTwitter color="white" size='1.75rem' /></a>
								<a href="https://www.youtube.com/StantonDJ4Life" aria-label="Youtube"><BsYoutube color="white" size='1.75rem' /></a>
							</div>
						</div>
					</Offcanvas.Body>

				</Navbar.Offcanvas>

			</Container>
		</Navbar>
	)
}
